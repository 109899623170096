import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo_color.png";

import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation();

  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <img src={Logo} alt="Logo" />
                </Link>
                <Text classes="copyright-txt">
                  &copy; {`${new Date().getFullYear()} ${t("Global.CasAppRightsReserved")}`}
                </Text>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-8 ml-auto">
            <Widget title={t("Global.NeedInformation")}>
              <List classes="widget-list">
                <LI>
                  <Link
                    to={`${process.env.PUBLIC_URL + "/termes-et-conditions"}`}
                  >
                    {t("Global.TermsConditions")}
                  </Link>
                </LI>
                <LI>
                  <Link
                    to={`${
                      process.env.PUBLIC_URL + "/politique-de-confidentialite"
                    }`}
                  >
                    {t("Global.PrivacyPolicy")}
                  </Link>
                </LI>
              </List>
            </Widget>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
