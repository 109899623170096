import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Member from "./Member";

import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  const FeaturedProfessionals = t("MarketingFeaturedProfessionals");

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    autoplay: false,
    dots: true,
    className: "team-content-wrap slick-dots--dark mtn-md-2",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="members">
      <div className="container-fluid top-margin-50">
        <div className="row align-items-center">
          <div className="col-12">
            <SectionTitle
              style={{ color: "#000" }}
              classesName="section-title--dark"
              variant="light"
              title="CasApp"
              heading={t("Global.BestProfessionalsThereAre")}
              text={t("Global.ProfessionalGoalMessage")}
            />
          </div>

          <div className="col-12">
            <SlickSlider settings={settings}>
              {FeaturedProfessionals.map((team) => (
                <div key={team.id}>
                  <Member
                    id={team.id}
                    name={team.name}
                    designation={team.designation}
                    profilePic={team.profilePic}
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
