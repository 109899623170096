import React from 'react'
import heroBg from '../../assets/img/01.jpg'

import {useTranslation} from "react-i18next";

export const HeaderTwo = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="hero-section" style={{ backgroundImage: `url(${heroBg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="hero-content-wrap">
                                <div className="hero-content text-center">
                                    <h1><strong>CasApp</strong></h1>
                                    <p>{t("Global.NewComingSoon")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
