import React from "react";
import parse from "html-react-parser";

const Page = ({data}) => {
  return (
    <div class="container" style={{ marginTop: 20, marginBottom: 70 }}>
      {data.map((item, i) => {
        switch (item.style) {
          case "box":
            return (
              <div key={i} class="alert alert-primary" role="alert">
                {parse(item.text)}
              </div>
            );
          case "H1":
            return (
              <h2 key={i} className="container-terms">
                {parse(item.text)}
              </h2>
            );
          case "normal":
            return (
              <p key={i} className="container-terms">
                {parse(item.text)}
              </p>
            );
          case "bullet":
            return (
              <ul key={i} className="ul-terms">
                <li>
                {parse(item.text)}
                </li>
              </ul>
            );
          default:
            return (
              <p key={i} className="container-terms">
                {parse(item.text)}
              </p>
            );
        }
      })}
    </div>)
}

export default Page;