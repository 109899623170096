import React, { useState } from "react";
import FormInput from "../../components/UI/Input";
import NumberFormat from "react-number-format";
import { Formik } from "formik";

import { ModalLoading } from "../../components/ModalLoading/ModalLoading";
import {useTranslation} from "react-i18next";

const From = () => {
  const {t} = useTranslation();
  const [Loading, setLoading] = useState(false);
  const [Isvisible, setIsvisible] = useState(false);

  const handleClose = () => {
    setIsvisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    setLoading(false);
    setIsvisible(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          prenom: "",
          lastName: "",
          email: "",
          phone: "",
          check: false,
          message: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = t("Contact.Error.InsertEmail");
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t("Contact.Error.InsertValidEmail");
          }

          if (!values.phone) {
            errors.phone = t("Contact.Error.InsertPhone");;
          } else if (values.phone.trim().length < 12) {
            errors.phone =
            t("Contact.Error.PhoneMinimum");;
          }

          if (!values.prenom) {
            errors.prenom = t("Contact.Error.InsertGivenName");;
          }

          if (!values.lastName) {
            errors.lastName = t("Contact.Error.InsertLastName");;
          }

          if (!values.check) {
            errors.check = t("Contact.Error.AcceptConditions");;
          }

          if (!values.message) {
            errors.message = t("Contact.Error.InsertMessage");;
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            handleSubmit(values);
            setSubmitting(false);
            resetForm({
              prenom: "",
              lastName: "",
              email: "",
              phone: "",
              check: false,
              message: "",
            });
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className="contact-form-wrap">
            {!Loading ? (
              <form id="contact-form" onSubmit={(event) => handleSubmit(event)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className=" text-uppercase font-weight-bold"
                      >
                        {t("Global.GivenName")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        name="prenom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prenom}
                      />
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <p>
                          {errors.prenom && touched.prenom && errors.prenom}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className=" text-uppercase font-weight-bold"
                      >
                        {t("Global.FamilyName")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <p>
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="text-uppercase font-weight-bold"
                      >
                        {t("Global.Email")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        aria-describedby="emailHelp"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <p>{errors.email && touched.email && errors.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="text-uppercase font-weight-bold"
                      >
                        {t("Global.PhoneNumber")}
                      </label>
                      <NumberFormat
                        format="###-###-####"
                        name="phone"
                        value={values.phone}
                        type="tel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      />

                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <p>{errors.phone && touched.phone && errors.phone}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label
                      htmlFor=""
                      className="text-uppercase font-weight-bold"
                    >
                      {t("Global.YourMessage")}
                    </label>
                    <textarea
                      class="form-control"
                      aria-label="With textarea"
                      name="message"
                      placeholder={t("Global.WriteYourMessage")}
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <p>
                        {errors.message && touched.message && errors.message}
                      </p>
                    </div>
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="check"
                        value={values.check}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        <a
                          href={`${
                            process.env.PUBLIC_URL + "termes-et-conditions"
                          }`}
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Global.AcceptTermsConditions")}
                        </a>
                      </label>
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <p>{errors.check && touched.check && errors.check}</p>
                      </div>
                    </div>

                    <FormInput
                      tag={"button"}
                      classes={"btn-outline"}
                      disabled={isSubmitting}
                    />

                    <div className="form-message" />
                  </div>
                </div>
              </form>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t("Global.Loading")}</span>
                </div>
              </div>
            )}

            {Isvisible ? <ModalLoading handleClose={handleClose} /> : null}
          </div>
        )}
      </Formik>
    </>
  );
};

export default From;
