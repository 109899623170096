import React from 'react';

function LogoItem(props) {

    const Background = require(`../../assets/img/${props.logoSrc}`);

    return (
        <a href={props.URL}><div className="brand-logo-item">
            <img className='brand-logo-img' src={Background} alt="Logo"/>
        </div></a>
    );
}

export default LogoItem;