import React from "react";
import {useTranslation} from "react-i18next";

import qrandroid from "../../assets/img/qr-android.png";
import qrapple from "../../assets/img/qr-apple.png";

export const Register = () => {
  const {t} = useTranslation();

  return (
    <div className={"contact-page-area-wrapper mt-4 mb-4"}>
      <div className="container contact-content-wrap p-1 p-sm-5">
        <h3>{t("Global.JoinUs")}</h3>
        <div className="row align-items-center">
          <div className="col-md-4 android-qr">
            <a href="https://play.google.com/store/apps/details?id=ca.casapp.mobile"><img src={qrandroid} alt={`${t("Global.QRCodeFor")} Android`}/></a>
          </div>
          <div className="col-md-4">
            <a href="https://apps.apple.com/ca/app/casapp/id1585526915"><img src={qrapple} alt={`${t("Global.QRCodeFor")} Apple`}/></a>
          </div>
        </div>
      </div>
    </div>
  );
};
