import React, { Fragment } from "react";
import Header from "../components/Header";
import ContactPage from "../templates/Contact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { HeaderTwo } from "../components/HeaderTwo/HeaderTwo";

import {useTranslation} from "react-i18next";

const PageContact = () => {
  const {t} = useTranslation();
  document.title = `${t("Global.ContactUs")} | CasApp Inc`;

  return (
    <Fragment>
      <Header />
      <HeaderTwo />
      <ContactPage />
      <CallToAction />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageContact;
