import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "../config/firebase.config";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [socialProfile, setSocialProfile] = useState(null); 
  const [token, setToken] = useState(null);

  const socialMediaAuth = (provider) => {
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        setUser(res.user);

        if (res.additionalUserInfo?.isNewUser) {
          setIsNewUser(true);
        }

        if (res.additionalUserInfo?.profile) {
          setSocialProfile(res.additionalUserInfo?.profile);
        }

        return res.user;
      })
      .catch((er) => {
        return er;
      });
  };

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const sendEmailVerification = async () => {
    const user = firebase.auth().currentUser;
    try {
      await user.sendEmailVerification({
        url: window.location.href,
        handleCodeInApp: true,
      });
    } catch (error) {
      console.log("sendEmailVerification failed: ", error.message);
    }
  };

  const signup = async (email, password) => {
    try {
      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      const user = firebase.auth().currentUser;
      setUser(user);
      //
      if (response.additionalUserInfo?.isNewUser) {
        setIsNewUser(true);
      } else {
        setIsNewUser(false);
      }
    } catch (error) {
      console.error("signup failed: ", error);
    }
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const updateProfile = async (displayNamem, photoURL) => {
    try {
      console.log("updateProfile start");
      const user = firebase.auth().currentUser;
      await user.updateProfile({
        displayName: displayNamem,
        photoURL: photoURL,
      });
      const userUpdated = firebase.auth().currentUser;
      setUser(userUpdated);
      console.log("updateProfile ends");
    } catch (error) {
      console.error("updateProfile failed: ", error);
    }
  };

  const getFirstName = () => {
    const user = firebase.auth().currentUser;
    var arr = user.displayName.split(" ");
    if (arr.length === 1) {
      return "";
    } else {
      return arr.slice(-1).join(" ");
    }
  };

  const getLastName = () => {
    var arr = user.displayName.split(" ");
    if (arr.length === 1) {
      return arr[0];
    } else {
      return arr.slice(0, -1).join(" "); // returns "Paul Steve"
    }
  };

  const signInAnonymous = () => {
    return firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        setUser(false);
      });
  };  

  const loadUserToken = async () => {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    setToken(idToken);
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        loadUserToken();
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  
  // Return the user object and auth methods
  return {
    user,
    token,
    isNewUser,
    signin,
    signup,
    signout,
    signInAnonymous,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendEmailVerification,
    socialMediaAuth,
    socialProfile,
    updateProfile,
    getFirstName,
    getLastName,
  };
}
