import React, { Fragment, useState } from "react";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import { Register } from "../components/ModalRegister/register";
import { LocationContext } from "../Context/LocationContext";

import {useTranslation} from "react-i18next";

export const Envoyer = () => {
  const {t} = useTranslation();

  const [location, setLocation] = useState([]);

  document.title = `${t("Global.ImIn")} | CasApp Inc`;

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      <Fragment>
        <Header />
        <Register location={location} />
        <CallToAction />
        <Footer />
        <MobileMenu />
      </Fragment>
    </LocationContext.Provider>
  );
};
