import React from "react";
import SlickSlider from "../../UI/Slick";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const NextArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-right" />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-left" />
    </button>
  );
};

const Slider = () => {
  const {t} = useTranslation();
  const SliderData = t("Slider.images");

  const getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
  
    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
  }

  const settings = {
    arrows: false,
    dots: false,
    spedd: 2000,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={"slider-area slider-area--2"}>
      <SlickSlider settings={settings}>
        {SliderData.map((item) => (
          <div key={item.id}>
            <div
              className="slider-item hero-section "
              style={{
                backgroundImage: `url(${require("../../../assets/img/" +
                  item.bg)})`,
              }}
            ></div>
          </div>
        ))}
      </SlickSlider>
      <div className="container seccion-header">
        <div className="row">
          <div className="col-lg-10 m-auto text-center">
            <div className="seccion-header-content light">
              <h2>{t("Slider.title")}</h2>
              <p className="m-auto">{t("Slider.text")}</p>
              <Link
                className="btn btn-brand mb-2"
                to={"direct-download"}
                target={(getOS() !== "iOS" && getOS() !== "Android") ? undefined : "_blank"}
              >
                {t("Slider.btnText")}
              </Link>
              <p className="m-auto ">{t("Slider.text2")}</p>
              <p className="m-auto ">{t("Slider.text3")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
