import React, {Component} from 'react';
import logo from '../../assets/img/logo_white.png'

class Logo extends Component {
    render() {
        return (
            <div>
                <img src={logo} alt="CasApp-Logo"   />
            </div>
        );
    }
}

export default Logo;