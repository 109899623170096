import React, { Fragment } from 'react';


function Member(props) {
    return (
        <Fragment>
            {props.type === 'page' ? (
                <div className="col-sm-6 col-lg-3">
                    <div className="team-mem-item">
                        <figure className="member-pic"  >
                            <img src={require('../../../assets/img/' + props.profilePic)} alt={props.name} />
                        </figure>
                        <div className="member-info">
                            <h5>{props.name}</h5>
                            <span className="designation">{props.designation}</span>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className="team-mem-item">
                        <figure className="member-pic">
                            <img src={require('../../../assets/img/' + props.profilePic)} alt={props.name} />
                        </figure>
                        <div className="member-info">
                            <h5>{props.name}</h5>
                            <span className="designation">{props.designation}</span>
                        </div>
                    </div>
                )}
        </Fragment>
    );
}

export default Member;