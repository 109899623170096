import React, { useEffect } from 'react';
import Logo from './Logo'
import data from '../../data/Navbar/navbar.json';
import { Link } from 'react-router-dom';

import {useTranslation} from "react-i18next";

const Header = () => {

    const {t} = useTranslation();

    const isSticky = (e) => {
        const header = document.querySelector('#navbar');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('sticky-top') : header.classList.remove('sticky-top');
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-brandcolor pl-5" id="navbar">
            <div className="navbar-brand">
                <Logo />
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mr-auto">

                    {
                        data.map((item,index) =>

                            <li className="nav-item active" key={index}>
                                <Link to={`${process.env.PUBLIC_URL + item.link}`} className="nav-link">{t(item.title)}</Link>
                            </li>
                        )
                    }
                    
                </ul>
            </div>
        </nav> 
    );
}

export default Header;