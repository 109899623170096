import React from 'react';
import SectionTitle from '../UI/SectionTitle'
import ServiceItem from  './ServiceItem'

import serviceTopBg from '../../assets/img/service/photo_city.jpg'

import {useTranslation} from "react-i18next";

const Services = ({classes}) => {
    const {t} = useTranslation();
    const ServicesData = t("ServicesFromCasApp");

    return (
        <div className={`service-area-wrapper ${classes}`}>
            <div className="service-area-top" style={{backgroundImage: `url("${serviceTopBg}")`,backgroundSize:'cover'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5 m-auto text-center">
                            <SectionTitle style={{color: "#fff"}} classesName="section-title--light" title={t("Global.TheAdvantages")} heading={t("Global.DoWhatYouDoBestMessage")} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-content-area">
                <div className="container">
                    <div className="row mtn-30">
                        {
                            ServicesData.map(service=>(
                                <ServiceItem key={service.id} id={service.id} title={service.title} text={service.shortDesc} thumb={service.thumb}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;