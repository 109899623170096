import React from 'react'
import {useTranslation} from "react-i18next";

export const ModalLoading = ({handleClose}) => {
    const {t} = useTranslation();

    return (
        <div className="modal-background" >
            <div className="modal fade show" id="staticBackdrop"
                style={{ display: 'block' }} data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{t("Global.Congrats")}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                            onClick={handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {t("Global.MessageSentSuccess")}
                         </div>                      
                    </div>
                </div>
            </div>

        </div>
    )
}
