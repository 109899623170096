import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-two";
import BrandLogo from "../components/BrandLogo";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";

import {useTranslation} from "react-i18next";

const PageAbout = () => {
  const {t} = useTranslation();
  document.title = `${t("Global.AboutOurselves")} | CasApp Inc`;

  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/page-header.jpg")}
        title={t("Global.OUR_MISSION")}
        content={t("Global.CasAppAllowsSelfEmployedMessage")}
      />
      <About/>
      <Features classes={"sm-top"} />
      <BrandLogo />
      <CallToAction />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
