import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { Link } from "react-router-dom";

import {useTranslation} from "react-i18next";

const CallToAction = () => {
  const {t} = useTranslation();

  const clientFlow = t("CallToAction.ClientFlowIMG");
  const professionalFlow = t("CallToAction.ProfessionalFlowIMG");

  return (
    <div className="call-top-action-wrap sp-y">
      <div className="container">
        <div className="footer-top-content">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2>{t("CallToAction.title")}</h2>
            </div>
          </div>
          <div className="casapp-flow">
            <div className="casapp-flow-column">
              <div className="bottom-margin-20">
                <h5>
                  <b>{t("Global.Clients")}</b>
                </h5>
              </div>
              <div className="bottom-margin-20">
                <img
                  src={require(`../../assets/img/calltoaction/${clientFlow}`)}
                  alt="casapp flow client"
                  className="casapp-flow"
                />
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.CreateYourProfileCOL")}</b>{t("CallToAction.ClientEnterYourInfoMessage")}
                </p>
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.ChooseYourServiceCOL")}</b>{t("CallToAction.ChooseFromServicesMessage")}
                </p>
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.WelcomeProfessionalCOL")}</b>{t("CallToAction.WaitForProfessionalMessage")}
                </p>
              </div>
            </div>
            <div className="casapp-flow-column">
              <div className="bottom-margin-20">
                <h5>
                  <b>{t("Global.Professionals")}</b>
                </h5>
              </div>
              <div className="bottom-margin-20">
                <img
                  src={require(`../../assets/img/calltoaction/${professionalFlow}`)}
                  alt="casapp flow professionel"
                  className="casapp-flow"
                />
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.CreateYourProfileCOL")}</b>{t("CallToAction.ProfessionalEnterYourInfoMessage")}
                </p>
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.PublishYourServiceCOL")}</b>{t("CallToAction.EnterYourServiceInfoMessage")}
                </p>
              </div>
              <div className="bottom-margin-20">
                <p>
                  <b>{t("CallToAction.OfferYourServiceCOL")}</b>{t("CallToAction.GoToClientMessage")}
                </p>
              </div>
            </div>
          </div>
          <div className="bottom-margin-20">
            <div className="">
              <p>{parse(t("CallToAction.text"))}</p>
            </div>
          </div>
          <div className="">
            <div className="">
              <Link
                to="contact"
                className="btn-outline"
              >
                {t("CallToAction.btnText")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
