import React, { Fragment, useEffect } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

import {useTranslation} from "react-i18next";
import Page from "../components/Page";

const PrivacyPolicy = () => {
  const {t} = useTranslation();
  const PrivacyPolicyData = t("PrivacyPolicy");

  useEffect(() => {
    document.title = "Politique de confidentialité | CasApp Inc";
  }, []);
  return (
    <Fragment>
      <Header />
      <PageHeader
        title="Politique de confidentialité"
        bgImg={require("../assets/img/page-header.jpg")}
      />
      <Page data={PrivacyPolicyData} />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PrivacyPolicy;
