import React from 'react';
import LogoItem from './LogoItem'

import {useTranslation} from "react-i18next";

function BrandLogo(props) {

    const {t} = useTranslation();

    const BrandLogos = [
        {
          id: 1,
          logoSrc: t("Badges.Apple"),
          URL: "https://apps.apple.com/ca/app/casapp/id1585526915"
        },
        {
          id: 2,
          logoSrc: t("Badges.Google"),
          URL: "https://play.google.com/store/apps/details?id=ca.casapp.mobile"
        },
        {
          id: 4,
          logoSrc: "brand-logo/quebec.png",
          URL: "/"
        }
      ]
    
    return (
        <div className="brand-logo-area sm-top">
            <div className="container">
                <div className="row align-items-center brand-logo-wrapper">
                    {BrandLogos.map(logo => (
                        <LogoItem key={logo.id} logoSrc={logo.logoSrc} URL={logo.URL}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BrandLogo;