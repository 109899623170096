import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAF-Gr8Ziua1xUaZKf-vCt5uw4myf4Fs_A",
  authDomain: "casapp-services.firebaseapp.com",
  databaseURL: "https://casapp-services-default-rtdb.firebaseio.com",
  projectId: "casapp-services",
  storageBucket: "casapp-services.appspot.com",
  messagingSenderId: "128233375991",
  appId: "1:128233375991:web:6091ae258440b23b7b6bf2",
  measurementId: "G-GFH0CLZVSE",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
