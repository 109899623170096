import React from "react";
import parse from "html-react-parser";
import Content from "../../UI/Content";
import { Link } from "react-router-dom";

import {useTranslation} from "react-i18next"

const About = () => {

  const {t} = useTranslation();

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <div className="row align-items-lg-center">
          <div className="col-12">
            <Content classes="about-content">
              <h6>{t("About.HomeOne.title")}</h6>
              <h2>{parse(t("About.HomeOne.heading"))}</h2>
              <p>{parse(t("About.HomeOne.text"))}</p>
              <Link
                to="about"
                className="btn-about"
              >
                {t("About.HomeOne.btnText")} <i className="fa fa-angle-double-right" />
              </Link>
            </Content>
          </div>
        </div>
      </div>
    </div>
  )
};

export default About;
