import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ProvideAuth } from "./services/auth";

/*
 * @ All pages Import
 */

import HomeOne from "./pages/HomeOne";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import { Envoyer } from "./pages/Envoyer";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import usePageTracking from "./Context/usePageTracking";

import {useTranslation} from "react-i18next"
import { getLng } from "./i18n";

const getOS = () => {
  var uA = navigator.userAgent || navigator.vendor || window.opera;
  if (
    (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
    (uA.includes("Mac") && "ontouchend" in document)
  )
    return "iOS";

  var i,
    os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
  for (i = 0; i < os.length; i++)
    if (new RegExp(os[i], "i").test(uA)) return os[i];
};

const linkTo = () => {
  const os = getOS();
  if (os === "iOS") {
    return "https://apps.apple.com/ca/app/casapp/id1585526915";
  } else if (os === "Android") {
    return "https://play.google.com/store/apps/details?id=ca.casapp.mobile";
  } else {
    return `${process.env.PUBLIC_URL + "/register"}`;
  }
};

const App = () => {
  const {i18n} = useTranslation();

  useEffect(() => {
    const lng = getLng();
    i18n.changeLanguage(lng);
  }, [i18n])

  usePageTracking();
  return (
    <ProvideAuth>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/register"}`}
            component={Envoyer}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/termes-et-conditions"}`}
            component={TermsConditions}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/politique-de-confidentialite"}`}
            component={PrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/direct-download"}`}
            component={() => {
              return (
                <div>{(window.location.href = linkTo()) && "Loading..."}</div>
              );
            }}
          />
          <Route exact component={Error404} />
        </Switch>
      </ScrollToTop>
    </ProvideAuth>
  );
};

export default App;
