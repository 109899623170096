import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import Page from "../components/Page";

import {useTranslation} from "react-i18next";

const TermsConditions = () => {
  const {t} = useTranslation();
  document.title = `${t("Global.TermsConditions")} | CasApp Inc`;
  const TermsConditionsData = t("TermsConditions");

  return (
    <Fragment>
      <Header />
      <PageHeader
        title={t("Global.TermsConditions")}
        bgImg={require("../assets/img/page-header.jpg")}
      />
      <Page data={TermsConditionsData} />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default TermsConditions;
