import React from 'react';


function ServiceItem(props) {
    const Background = require(`../../assets/img/${props.thumb}`);
    return (
        <div className="col-sm-6 col-lg-4">
            <div className="service-item">
                <figure className="service-thumb">                    
                        <div style={{backgroundImage:"url(" +  Background  + ")",height:250,width:400,backgroundPosition:'center',backgroundSize:'cover'}}>
                        </div>                   
                    <figcaption className="service-txt">
                        <h5>{props.title}</h5>
                    </figcaption>
                </figure>
                <div className="service-content">
                    <div className="service-content-inner">
                        <h5>
                           {props.title}
                        </h5>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;