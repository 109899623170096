import React from 'react';
import parse from "html-react-parser";
import aboutThumb from '../../../assets/img/about-2-bg.jpg'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next"

const About = () => {
    const {t} = useTranslation();

    return (
        <div className="home-two-about-area" style={{backgroundImage:`url(${aboutThumb})`}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 d-lg-none">
                        <figure className="about-thumb">
                            <img src={require('../../../assets/img/about-2.jpg')} alt="Businex-About"/>
                        </figure>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content about-content--2">
                            <h6>{t("About.HomeTwo.title")}</h6>
                            <h2>{parse(t("About.HomeTwo.heading"))}</h2>
                            <span className="about-since">{t("About.HomeTwo.since")}</span>
                            <p>{parse(t("About.HomeTwo.text"))}</p>
                            <Link to="register" className="btn btn-brand mb-2">{t("About.HomeTwo.btnText")} <i className="fa fa-angle-double-right"/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
