import React from "react";
import From from "./From";

import {useTranslation} from "react-i18next";

const ContactPage = () => {
  const {t} = useTranslation();

  return (
    <div className={"contact-page-area-wrapper sp-y"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-11">
              <div className="contact-form-area contact-method">
                <h3>{t("Global.WriteUsMessage")}</h3>
                <From />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
